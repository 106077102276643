// eslint-disable-next-line import/named
import { API_URL } from './config';

const options = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

class ProofApi {
  getProof(id) {
    try {
      const rqOptions = { ...options, method: 'GET' };

      return fetch(`${API_URL}ticket/stadium/${id}/proof`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve([]);
    }
  }

  createProof(data) {
    try {
      const rqOptions = {
        ...options,
        method: 'POST',
        body: JSON.stringify(data),
      };

      return fetch(`${API_URL}ticket/stadium/proof`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve({});
    }
  }

  getFeaturedExamples() {
    try {
      const rqOptions = { ...options, method: 'GET' };

      return fetch(`${API_URL}ticket/stadium/featured`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve([]);
    }
  }

  createInstantProof(data) {
    try {
      const rqOptions = {
        ...options,
        method: 'POST',
        body: JSON.stringify(data),
      };

      return fetch('/save.cfm', rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve({});
    }
  }

  updateProof(data) {
    try {
      const rqOptions = {
        ...options,
        method: 'PUT',
        body: JSON.stringify(data),
      };

      return fetch(`${API_URL}ticket/stadium/${data.id}/proof`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve({});
    }
  }

  updateProofFeatured(data) {
    try {
      const rqOptions = {
        ...options,
        method: 'PUT',
        body: JSON.stringify(data),
      };

      return fetch(
        `${API_URL}ticket/${data.type}/${data.id}/proof/featured`,
        rqOptions
      )
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve({});
    }
  }
}

export const proofApi = new ProofApi();
