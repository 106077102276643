/* eslint-disable no-unreachable */
import { sign, decode, JWT_SECRET, JWT_EXPIRES_IN } from '../utils/jwt';
import { API_URL } from './config';

class AuthApi {
  async login({ email, password }) {
    const rqOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: email, password }),
    };

    return fetch(`${API_URL}login`, rqOptions)
      .then((r) => r.json())
      .then((d) => {
        if (d.error) {
          return Promise.reject(new Error(d.messages[0]));
        }
        return sign({ token: d.data }, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN,
        });
      })
      .catch(() => Promise.reject(new Error('Internal server error')));
  }

  async register({ email: username, firstname, lastname, password }) {
    const checkOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    const { exists } = await fetch(
      `${API_URL}user/${username}/check`,
      checkOptions
    )
      .then((r) => r.json())
      .then((d) => {
        if (d.error) {
          return new Error(d.messages[0]);
        }
        return d.data;
      })
      .catch(() => Promise.reject(new Error('Internal server error')));

    if (exists) {
      return new Promise((resolve, reject) => {
        reject(new Error('User already exists'));
      });
    }

    const registerOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        username,
        firstname,
        lastname,
        password,
        permissions: ['customer'],
      }),
    };

    return fetch(`${API_URL}register`, registerOptions)
      .then((r) => r.json())
      .then((d) => {
        if (d.error) {
          return new Error(d.messages[0]);
        }
        return sign({ token: d.data }, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN,
        });
      })
      .catch(() => Promise.reject(new Error('Internal server error')));
  }

  async passwordRecovery({ username }) {
    const rqOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username }),
    };

    return fetch(`${API_URL}passwordRecovery`, rqOptions)
      .then((r) => r.json())
      .then((d) => {
        if (d.error) {
          return Promise.reject(new Error(d.messages[0]));
        }
        return d.data;
      })
      .catch(() => Promise.reject(new Error('Internal server error')));
  }

  async passwordReset({ username, password, token }) {
    const rqOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password, token }),
    };

    return fetch(`${API_URL}passwordReset`, rqOptions)
      .then((r) => r.json())
      .then((d) => {
        if (d.error) {
          return Promise.reject(new Error(d.messages[0]));
        }
        return d.data;
      })
      .catch(() => Promise.reject(new Error('Internal server error')));
  }

  me(accessToken) {
    const { token } = decode(accessToken);

    const rqOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    return fetch(`${API_URL}whoami`, rqOptions)
      .then((r) => r.json())
      .then((d) => {
        if (d.error) {
          return Promise.reject(new Error(d.messages[0]));
        }
        return d.data;
      })
      .catch(() => Promise.reject(new Error('Internal server error')));

    // return new Promise((resolve, reject) => {
    //   try {
    //     // Decode access token
    //     const { token } = decode(accessToken);

    //     // Find the user
    //     // const user = users.find((_user) => _user.id === userId);

    //     if (!user) {
    //       reject(new Error('Invalid authorization token'));
    //       return;
    //     }

    //     resolve({
    //       id: user.id,
    //       avatar: user.avatar,
    //       email: user.email,
    //       name: user.name,
    //       plan: user.plan,
    //     });
    //   } catch (err) {
    //     console.error('[Auth Api]: ', err);
    //     reject(new Error('Internal server error'));
    //   }
    // });
  }
}

export const authApi = new AuthApi();
