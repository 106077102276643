/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-wrap-multilines */
import { useRef, useState, useEffect, useCallback } from 'react';
// import { subDays, subHours } from 'date-fns';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import BellIcon from '../../icons/Bell';
import ChatAltIcon from '../../icons/ChatAlt';
import CreditCardIcon from '../../icons/CreditCard';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ClearIcon from '@material-ui/icons/Clear';
import useAuth from '../../hooks/useAuth';
import { notificationsApi } from '../../api/notificationsApi';
import useMounted from '../../hooks/useMounted';
import { useNavigate } from 'react-router';

// const now = new Date();

// const notifications = [
//   {
//     id: '5e8883f1b51cc1956a5a1ec0',
//     createdAt: subHours(now, 2).getTime(),
//     description: 'Dummy text',
//     title: 'Your order is placed',
//     type: 'order_placed',
//   },
//   {
//     id: '5e8883f7ed1486d665d8be1e',
//     createdAt: subDays(now, 1).getTime(),
//     description: 'You have 32 unread messages',
//     title: 'New message received',
//     type: 'new_message',
//   },
//   {
//     id: '5e8883fca0e8612044248ecf',
//     createdAt: subDays(now, 3).getTime(),
//     description: 'Dummy text',
//     title: 'Your item is shipped',
//     type: 'item_shipped',
//   },
//   {
//     id: '5e88840187f6b09b431bae68',
//     createdAt: subDays(now, 7).getTime(),
//     description: 'You have 32 unread messages',
//     title: 'New message received',
//     type: 'new_message',
//   },
// ];

const iconsMap = {
  item_shipped: ShoppingCartIcon,
  new_message: ChatAltIcon,
  order_placed: CreditCardIcon,
  notification: NotificationsIcon,
  notification_active: NotificationsActiveIcon,
};

const useNotifications = () => {
  const mounted = useMounted();
  const [notifications, setNotifications] = useState([]);

  const getNotifications = useCallback(async () => {
    try {
      const data = await notificationsApi.getNotifications();

      if (mounted.current) {
        setNotifications(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return { notifications };
};

const NotificationsPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const { notifications: fetchedNotifications } = useNotifications(user.id);
  const [notifications, setNotifications] = useState(fetchedNotifications);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(
    () => setNotifications(fetchedNotifications),
    [fetchedNotifications]
  );

  const markSeen = (noti) => {
    if (noti.seen === 0) {
      setNotifications((currentNotifications) =>
        currentNotifications.map((notification) =>
          notification.id !== noti.id
            ? notifications
            : { ...notification, seen: 1 }
        )
      );
      notificationsApi.markSeen(noti.id);
    }

    navigate(noti.to);
    handleClose();
  };

  const handleDeleteNotification = (noti) => {
    if (noti.active === 1) {
      setNotifications((currentNotifications) =>
        currentNotifications.filter(
          (notification) => notification.id !== noti.id
        )
      );
      notificationsApi.delete(noti.id);
    }
  };

  const handleMarkAllSeen = () => {
    notifications.forEach((el) => {
      notificationsApi.markSeen(el.id);
    });

    setNotifications((current) =>
      current.map((notification) => ({ ...notification, seen: 1 }))
    );
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={anchorRef} onClick={handleOpen}>
          <Badge
            color="error"
            badgeContent={notifications.filter((n) => n.seen === 0).length}
          >
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 600 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="h6">
            Notifications
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map((notification) => {
                // const Icon = iconsMap[notification.type];
                const Icon =
                  notification.seen === 1
                    ? iconsMap.notification
                    : iconsMap.notification_active;

                return (
                  <ListItem divider key={notification.id}>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor:
                            notification.seen === 0
                              ? 'primary.main'
                              : 'primary.secondary',
                          color: 'primary.contrastText',
                        }}
                      >
                        <Icon fontSize="small" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Link
                          color="textPrimary"
                          sx={{ cursor: 'pointer' }}
                          underline="none"
                          variant="subtitle2"
                          onClick={() => markSeen(notification)}
                        >
                          {notification.subject}
                        </Link>
                      }
                      secondary={notification.message}
                    />
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteNotification(notification)}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </ListItem>
                );
              })}
            </List>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
              }}
            >
              <Button
                color="primary"
                size="small"
                variant="text"
                onClick={handleMarkAllSeen}
              >
                Mark all as read
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
