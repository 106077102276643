// import { useState, useEffect } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import HomeHeroImg from '../../../build/static/home/HERO.jpg';
import { Box, Container, Typography, Grid } from '@material-ui/core';
// import { makeStyles } from '@mui/styles';

const heroBg = {
	backgroundImage: 'url(/static/home/HERO.jpg)',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
};

const HomeHero = (props) => (
	<Box
		sx={{
			backgroundColor: 'background.paper',
			mb: '-7px',
		}}
		{...props}
	>
		<Container
			style={heroBg}
			// maxWidth="xl"
			maxWidth="100%"
			sx={{
				p: { xs: 0 },
				height: { xs: '400px', sm: '500', lg: '550px', xl: '600px' },
			}}
		>
			<Container maxWidth="lg">
				<Grid container spacing={3}>
					<Grid item xs={3} sm={5} md={5} lg={5} />
					<Grid item xs={9} sm={7} md={7} lg={7}>
						<Typography
							style={{ textShadow: 'rgba(0, 0, 0, 0.6) 3px 3px 10px' }}
							sx={{
								color: 'primary.contrastText',
								pt: {
									xs: 7,
									sm: 8,
									md: 10,
									lg: 15,
								},
								textShadow: '2',
								textAlign: { xs: 'right', sm: 'left' },
								fontSize: {
									xs: '1.7rem',
									sm: '2.4rem',
									md: '3.2rem',
									lg: '4.5rem',
								},
							}}
							variant="h1"
						>
							YOU ARE INVITED!
						</Typography>
						<Typography
							style={{ textShadow: 'rgba(0, 0, 0, 0.6) 3px 3px 10px' }}
							sx={{
								lineHeight: 1,
								color: 'primary.contrastText',
								textAlign: { xs: 'right', sm: 'left' },
								fontWeight: '500',
								fontSize: {
									xs: '1.1rem',
									sm: '1.4rem',
									md: '1.5rem',
									lg: '2rem',
								},
							}}
							variant="h5"
						>
							Create your own, one-of-a-kind invitations and announcements for
							every occasion!
						</Typography>
					</Grid>
				</Grid>

				{/* <img
				alt="Hero"
				src="/static/home/HERO.jpg"
				style={{
					maxWidth: '100%',
					width: '100%',
					zIndex: 20,

					bottom: 0,
					left: 0,
					right: 0,
					top: 0,
				}}
			/> */}
				{/* <Button style={btn}>Styled with Hook API</Button> */}
			</Container>
		</Container>
	</Box>
);

export default HomeHero;
