import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { proofApi as stadiumApi } from '../api/stadiumProofApi';
import { proofApi as photoApi } from '../api/photoProofApi';

const initialState = {
  stadiumTickets: [],
  photoTickets: [],
  categoryId: null,
};

const handlers = {
  INITIALIZE: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  SET_CATEGORY: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
};

// eslint-disable-next-line no-confusing-arrow
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const FeaturedTicketsContext = createContext({
  ...initialState,
});

export const FeaturedTicketsProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const photoTickets = photoApi.getFeaturedPhoto();
        const stadiumTickets = stadiumApi.getFeaturedExamples('stadium');

        Promise.all([photoTickets, stadiumTickets]).then((data) => {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              photoTickets: data[0],
              stadiumTickets: data[1],
            },
          });
        });
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            photoTickets: [],
            stadiumTickets: [],
          },
        });
      }
    };

    initialize();
  }, []);

  const setFeaturedTicketsCategory = (categoryId) => {
    dispatch({
      type: 'SET_CATEGORY',
      payload: {
        categoryId,
      },
    });
  };

  return (
    <FeaturedTicketsContext.Provider
      value={{
        ...state,
        setFeaturedTicketsCategory,
      }}
    >
      {children}
    </FeaturedTicketsContext.Provider>
  );
};

FeaturedTicketsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FeaturedTicketsContext;
