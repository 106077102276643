import { Box, Container, Typography } from '@material-ui/core';
import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// const settings = {
// 	dots: true,
// 	infinite: true,
// 	speed: 500,
// 	slidesToShow: 1,
// 	slidesToScroll: 1,
// };
const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

const HomeTestimonials = (props) => (
  <Box
    sx={{
      borderTop: '25px solid',
      borderColor: 'primary.main',
      backgroundColor: 'background.paper',
      py: 7,
    }}
    {...props}
  >
    <Container
      maxWidth="md"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Slider {...settings} style={{ width: '100%' }}>
        <div>
          <Typography align="center" color="textPrimary" variant="h6">
            &quot;The tickets are a very unique... I have never seen anything
            like them. It was great to get them as a baby announcement rather
            than the everyday card or &quot;candy type&quot; cigars! Everyone I
            have shown them to just loves them! I&apos;m a new grandma and I
            show everybody my grandson&apos;s picture and baby
            announcement!&quot;
          </Typography>

          <Typography color="textSecondary" align="center" mt={3}>
            Pat Newton,
            <br />
            Corfu, NY
          </Typography>
        </div>
        <div>
          <Typography align="center" color="textPrimary" variant="h6">
            &quot;I love the ticket birth announcements!! These were perfect
            since I have a lot of family out of state, they hold up well in the
            mail and no special packaging was needed. It also fits in my sons
            baby book and a scrapbook, and won&apos;t have to be thrown away
            after a year like candy bars and other typical announcements! Thanks
            so much!&quot;
          </Typography>

          <Typography color="textSecondary" align="center" mt={3}>
            Tricia Jesberg,
            <br />
            Wentzville, MO
          </Typography>
        </div>
        <div>
          <Typography align="center" color="textPrimary" variant="h6">
            &quot;Completely original- Everyone loved the birth announcements!
            They kept asking where I got them. You can&apos;t beat the price for
            an original personalized item like these! Thank you for making sure
            everything was accurate! They turned out great and then they were
            shipped so fast!! All expectant/ new parents need to know about your
            site.&quot;
          </Typography>

          <Typography color="textSecondary" align="center" mt={3}>
            Cassandra Hogue,
          </Typography>
        </div>
        <div>
          <Typography align="center" color="textPrimary" variant="h6">
            &quot;Hi! First of all, WOW!!! My daughter is turning 1, and my
            husband and brother are turning 30, so we are throwing a huge bash!
            We have received numerous compliments on our invitations! In fact,
            one friend stated that our invitations were the coolest! Thanks so
            much for helping us create excitement weeks before the big
            event!&quot;
          </Typography>

          <Typography color="textSecondary" align="center" mt={3}>
            Jennifer Books,
            <br />
            Columbia, MO
          </Typography>
        </div>
        <div>
          <Typography align="center" color="textPrimary" variant="h6">
            &quot;Your tickets were a blast. The bachelorette party I threw was
            a blast. Some of the first things out of the guest&apos;s mouths
            were, &quot;Where did you get those invitations?&quot; I not only
            guarantee that I will be using event ticketing again, but some of
            those guests mentioned that they would go home, look on the back of
            the ticket and visit the website to see what sort of invitations
            they may want to send in the future.&quot;
          </Typography>

          <Typography color="textSecondary" align="center" mt={3}>
            Stacy Chaney,
            <br />
            Oklahoma City, Oklahoma
          </Typography>
        </div>
      </Slider>
      {/* <Typography align="center" color="textPrimary" variant="h3">
				&quot;Devias builds some of the best templates you can find for React.
				They will save you time.&quot;
			</Typography>
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					mt: 3,
				}}
			>
				<Avatar src="/static/home/olivier.jpeg" sx={{ mr: 2 }} />
				<Typography color="textSecondary" variant="body2">
					Olivier Tassinari,
					<br />
					co-creator of @MaterialUI
				</Typography>
			</Box> */}
    </Container>
  </Box>
);

export default HomeTestimonials;
