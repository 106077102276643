import { useEffect, useRef, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import RTL from './components/RTL';
// import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
// import wait from './utils/wait';
import { resellerApi } from './api/resellerApi';
import HistoryContext from './contexts/HistoryRouterContext';
import { createBrowserHistory } from 'history';

import GA4 from './utils/GA4';

const useTheme = () => {
  const { settings, saveSettings } = useSettings();

  const [theme, setTheme] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'DARK',
    isLoaded: false,
  });

  let domain = window.location.hostname.replace('www.', '');
  if (domain.includes('set-specialeventticketing')) {
    domain = 'specialeventticketing.com';
  }

  // http://localhost:3000
  if (domain.includes('localhost')) {
    domain = 'specialeventticketing.com';
  }

  // http://127.0.0.1:3000
  if (domain.includes('127.0.0.1')) {
    domain = 'partyinvitations.buildyourticket.com';
  }

  if (
    domain.includes(
      'partyinvitations-specialeventticketing-com.janus-thesabresolution-com.vps.ezhostingserver.com'
    )
  ) {
    domain = 'partyinvitations.buildyourticket.com';
  }

  // domain = 'partyinvitations.buildyourticket.com';

  useEffect(async () => {
    resellerApi.getResellerByDomain(domain).then((d) => {
      console.log({ d });
      console.log({ domain });
      if (d && !d.id) {
        // window.location.assign('https://specialeventticketing.com');
      }

      try {
        if (d && d.theme) {
          const {
            theme: {
              primary: {
                main: { r: pr, g: pg, b: pb, a: pa },
                contrastText: { r: ptr, g: ptg, b: ptb, a: pta },
              },
              secondary: {
                main: { r: sr, g: sg, b: sb, a: sa },
                contrastText: { r: str, g: stg, b: stb, a: sta },
              },
            },
          } = d;

          const t = {
            primary: {
              main: `rgba(${pr},${pg},${pb},${pa})`,
              contrastText: `rgba(${ptr},${ptg},${ptb},${pta})`,
            },
            secondary: {
              main: `rgba(${sr},${sg},${sb},${sa})`,
              contrastText: `rgba(${str},${stg},${stb},${sta})`,
            },
          };

          saveSettings({ ...settings, reseller: d });

          setTheme({
            direction: settings.direction,
            responsiveFontSizes: settings.responsiveFontSizes,
            roundedCorners: settings.roundedCorners,
            theme: d.theme.base || 'LIGHT',
            options: { palette: t }, // pulled from DB
            domainTheme: { palette: t }, // pulled from DB
            isLoaded: true,
          });
        }
      } catch (e) {
        toast("There appear to be an issue loading this domain's theme");

        setTheme({
          direction: settings.direction,
          responsiveFontSizes: settings.responsiveFontSizes,
          roundedCorners: settings.roundedCorners,
          theme: settings.theme,
          options: {}, // pulled from DB
          domainTheme: {}, // pulled from DB
          isLoaded: true,
        });
      }
    });
  }, [window.location.hostname]);

  return theme;
};

const App = () => {
  const { current: history } = useRef(createBrowserHistory({ window }));

  const content = useRoutes(routes);
  const auth = useAuth();
  const { settings } = useSettings();
  const theme = useTheme();

  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const customTheme = createCustomTheme(theme);

  return (
    <ThemeProvider theme={customTheme}>
      <RTL direction={settings.direction}>
        <HistoryContext.Provider value={history}>
          <CssBaseline />
          <GA4 />
          <Toaster
            position="top-center"
            toastOptions={{
              style: {
                minWidth: 600,
              },
            }}
          />
          {/* <SettingsDrawer /> */}
          {auth.isInitialized && theme.isLoaded ? content : <SplashScreen />}
        </HistoryContext.Provider>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
