import { useEffect } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import AuthBanner from '../../components/authentication/AuthBanner';
import {
  LoginAmplify,
  LoginAuth0,
  LoginFirebase,
  LoginJWT,
} from '../../components/authentication/login';
// import Logo from '../../components/Logo';
// import Logo from '../../icons/logo.jpg';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';
import { Link as RouterLink } from 'react-router-dom';

// const platformIcons = {
//   Amplify: '/static/icons/amplify.svg',
//   Auth0: '/static/icons/auth0.svg',
//   Firebase: '/static/icons/firebase.svg',
//   JWT: '/static/icons/jwt.svg',
// };

const Login = () => {
  const { platform } = useAuth();

  useEffect(() => {
    gtm.push({
      event: 'page_view',
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | Special Event Ticketing</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AuthBanner />
        <Container
          maxWidth="sm"
          sx={{
            py: '80px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
            }}
          >
            {/* <RouterLink to="/">
              <img src={Logo} alt="" />
              <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink> */}
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  // mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" variant="h4">
                    Log in
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                {platform === 'Amplify' && <LoginAmplify />}
                {platform === 'Auth0' && <LoginAuth0 />}
                {platform === 'Firebase' && <LoginFirebase />}
                {platform === 'JWT' && <LoginJWT />}
              </Box>
              <Divider
                sx={{
                  my: 3,
                }}
              />
              <Box display="flex" justifyContent="space-between">
                <FormHelperText sx={{ textAlign: 'left' }}>
                  <RouterLink
                    to="/authentication/register"
                    style={{ float: 'left', color: 'primary' }}
                  >
                    Create Account?
                  </RouterLink>
                </FormHelperText>
                <FormHelperText sx={{ textAlign: 'right' }}>
                  <RouterLink
                    to="/authentication/password-recovery"
                    style={{ color: 'primary' }}
                  >
                    Forgot Password?
                  </RouterLink>
                </FormHelperText>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
