import { Box, Grid, Container, Typography, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const styles = {
	root: {
		background: 'white',
		border: 0,
		borderRadius: 1,
		color: 'inherit',
		height: '27px',
		fontSize: '20px',
		fontWeight: '300',
		boxShadow:
			'0 0 1px 0 rgba(0, 0, 0, 0.08),0 3px 4px -2px rgba(0, 0, 0, 0.08)',
	},
};

const HomeOverview = (props) => (
	<Box
		sx={{
			backgroundColor: 'primary.main',
			pt: 1,
			pb: 6,
		}}
		{...props}
	>
		<Typography
			sx={{
				color: 'primary.contrastText',
				textAlign: 'center',
				mb: '25px',
				fontWeight: 'normal',
				pt: { xs: '5px', sm: '20px', md: '0px' },
			}}
			variant="h2"
		>
			<Box sx={{ display: { xs: 'none', sm: 'none', md: 'inline' } }}>
				<img
					alt="Hero"
					src="/static/home/Home-ARROW-LEFT.png"
					style={{ position: 'relative', top: '24px' }}
				/>{' '}
			</Box>
			CHOOSE YOUR TICKET{' '}
			<Box sx={{ display: { xs: 'none', sm: 'none', md: 'inline' } }}>
				<img
					alt="Hero"
					src="/static/home/Home-ARROW-RIGHT.png"
					style={{ position: 'relative', top: '24px' }}
				/>
			</Box>
		</Typography>
		<Container maxWidth="lg">
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={1}
			>
				<Grid item xs={12} sm={6} md={4}>
					<Typography
						sx={{
							color: 'primary.contrastText',
							textAlign: {
								xs: 'center',
								sm: 'right',
							},
						}}
						variant="h4"
					>
						PHOTO STYLE
					</Typography>
					<Typography
						sx={{
							color: 'primary.contrastText',
							textAlign: { sm: 'right', xs: 'center' },
						}}
					>
						<Button
							component={RouterLink}
							to="/Build/Photo"
							sx={{
								display: { xs: 'inlineBlock', sm: 'none', md: 'none' },
								textAlign: 'center',
							}}
						>
							<img
								alt="Hero"
								src="/static/home/SET-MobileHome-PHOTO.jpg"
								style={{ width: '100%', border: '3px solid white' }}
							/>
						</Button>
						Your personalized 2&quot; x 5 1/2&quot; Photo Ticket will be printed
						on heavyweight high quality paper.
						<br />
						<Button
							size="small"
							component={RouterLink}
							to="/Build/Photo"
							variant="contained"
							sx={{
								...styles.root,
								borderRadius: '1px',
								minWidth: 'auto',
								mt: '5px',
								':hover': {
									backgroundColor: 'white',
								},
								'& span': {
									color: 'primary.main',
								},
							}}
						>
							GET STARTED
						</Button>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6} md={2} textAlign="center">
					<Box sx={{ display: { xs: 'none', sm: 'none', md: 'inline' } }}>
						<img alt="Hero" src="/static/home/ICON-PhotoTicket.png" />
					</Box>
					<Box
						sx={{
							display: { xs: 'none', sm: 'inline', md: 'none' },
							textAlign: 'center',
						}}
					>
						<img
							alt="Hero"
							src="/static/home/SET-MobileHome-PHOTO.jpg"
							style={{ width: '100%', border: '3px solid white' }}
						/>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={2}
					sx={{ display: { xs: 'inline', sm: 'inline', md: 'none' } }}
				>
					<br />
				</Grid>
				<Grid item xs={12} sm={6} md={2} textAlign="center">
					<Box sx={{ display: { xs: 'none', sm: 'none', md: 'inline' } }}>
						<img alt="Hero" src="/static/home/ICON-StadiumTicket.png" />
					</Box>
					<Box
						sx={{
							display: { xs: 'none', sm: 'inline', md: 'none' },
							textAlign: 'center',
						}}
					>
						<img
							alt="Hero"
							src="/static/home/SET-MobileHome-STADIUM.jpg"
							style={{ width: '100%', border: '3px solid white' }}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} sm={6} md={4} sx={{ pl: '20px' }}>
					<Typography
						sx={{
							color: 'primary.contrastText',
							textAlign: {
								xs: 'center',
								sm: 'left',
							},
						}}
						variant="h4"
					>
						STADIUM STYLE
					</Typography>
					<Typography
						sx={{
							color: 'primary.contrastText',
							textAlign: {
								xs: 'center',
								sm: 'left',
							},
						}}
					>
						<Button
							component={RouterLink}
							to="/Build/Stadium"
							sx={{
								display: { xs: 'inlineBlock', sm: 'none', md: 'none' },
								textAlign: 'center',
							}}
						>
							<img
								alt="Hero"
								src="/static/home/SET-MobileHome-STADIUM.jpg"
								style={{ width: '100%', border: '3px solid white' }}
							/>
						</Button>
						{/* Authentic concert & sporting event style ticket announcements and
						invitations for all your events.
						<br />
						<br />
						Printed on authentic ticket stock. Ticket size is 2&quot; x 5
						1/2&quot; Free envelops with every order. */}
						Event style 2&quot; x 5 1/2&quot; tickets and invitations printed on
						authentic ticket stock.
						<br />
						<Button
							size="small"
							component={RouterLink}
							to="/Build/Stadium"
							variant="contained"
							sx={{
								...styles.root,
								borderRadius: '1px',
								minWidth: 'auto',
								mt: '5px',
								':hover': {
									backgroundColor: 'white',
								},
								'& span': {
									color: 'primary.main',
								},
							}}
						>
							GET STARTED
						</Button>
					</Typography>
				</Grid>
			</Grid>
		</Container>
	</Box>
);

export default HomeOverview;
