export const homePageLinks = [
	{
		label: 'BUILD TICKET',
		to: '/build',
		children: [
			{
				label: 'PHOTO STYLE',
				to: '/Build/Photo',
			},
			{
				label: 'STADIUM STYLE',
				to: '/Build/Stadium',
			},
		],
	},
	{
		label: 'ADD ONS',
		to: '/Add-Ons',
		children: [
			{
				label: 'Envelopes',
				to: '/Envelopes',
			},
			{
				label: 'Lanyard & Pouch',
				to: '/Lanyard-Pouch',
			},
			{
				label: 'Magnet Backer',
				to: '/Magnet-Backer',
			},
			{
				label: 'Place Cards',
				to: '/Place-Cards',
			},
			{
				label: 'RSVP Envelope',
				to: '/RSVP',
			},
		],
	},
	{
		label: 'SHIPPING',
		to: '/Shipping',
	},
	{
		label: 'FAQS',
		to: '/FAQs',
	},
	{
		label: 'CONTACT US',
		to: '/Contact-Us',
	},
];
