// eslint-disable-next-line import/named
import { API_URL } from './config';

const options = {
  headers: {
    'Content-Type': 'application/json',
    // Accept: 'application/json',
  },
};

class ProofApi {
  getProof(id) {
    try {
      const rqOptions = { ...options, method: 'GET' };

      return fetch(`${API_URL}ticket/photo/${id}/proof`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve([]);
    }
  }

  createProof(data) {
    try {
      const rqOptions = {
        ...options,
        method: 'POST',
        body: JSON.stringify(data),
      };

      return fetch(`${API_URL}ticket/photo/proof`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve({});
    }
  }

  getFeaturedPhoto() {
    try {
      const rqOptions = { ...options, method: 'GET' };

      return fetch(`${API_URL}ticket/photo/featured`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve([]);
    }
  }

  getFeaturedStadium() {
    try {
      const rqOptions = { ...options, method: 'GET' };

      return fetch(`${API_URL}ticket/stadium/featured`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve([]);
    }
  }

  updateProof(data) {
    try {
      const rqOptions = {
        ...options,
        method: 'PUT',
        body: JSON.stringify(data),
      };

      return fetch(`${API_URL}ticket/photo/${data.id}/proof`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch((e) => {
          console.log({ e });
          Promise.reject(new Error('Internal server error'));
          console.log({ e });
        });
    } catch (e) {
      console.log({ e });
      return Promise.resolve({});
    }
  }
}

export const proofApi = new ProofApi();
