import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import AuthGuardFront from './components/AuthGuardFront';
import DashboardLayout from './components/dashboard/DashboardLayout';
// import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import GuestGuard from './components/GuestGuard';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages
// const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
// const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
// const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
// const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
// const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Dashboard pages
const DashboardResellerAnalytics = Loadable(
  lazy(() => import('./pages/dashboard/ResellerAnalytics'))
);
const DashboardAccount = Loadable(
  lazy(() => import('./pages/dashboard/Account'))
);
const CustomerDetails = Loadable(
  lazy(() => import('./pages/dashboard/CustomerDetails'))
);
const CustomerEdit = Loadable(
  lazy(() => import('./pages/dashboard/CustomerEdit'))
);
const CustomerList = Loadable(
  lazy(() => import('./pages/dashboard/CustomerList'))
);

const TicketImage = Loadable(
  lazy(() => import('./pages/dashboard/TicketImageList'))
);
const TicketImageCreate = Loadable(
  lazy(() => import('./pages/dashboard/TicketImageCreate'))
);

// Authentication pages

const GuestLogin = Loadable(
  lazy(() => import('./pages/authentication/GuestLogin'))
);
// const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/PasswordRecovery'))
);
const PasswordReset = Loadable(
  lazy(() => import('./pages/authentication/PasswordReset'))
);
const Register = Loadable(
  lazy(() => import('./pages/authentication/Register'))
);
const VerifyCode = Loadable(
  lazy(() => import('./pages/authentication/VerifyCode'))
);

// user pages
const UsersList = Loadable(lazy(() => import('./pages/dashboard/UsersList')));
const UserEdit = Loadable(lazy(() => import('./pages/dashboard/UserEdit')));

const Account = Loadable(lazy(() => import('./pages/Account')));
const Build = Loadable(lazy(() => import('./pages/Build')));
const Addons = Loadable(lazy(() => import('./pages/AddOns')));
const Shipping = Loadable(lazy(() => import('./pages/Shipping')));
const FAQs = Loadable(lazy(() => import('./pages/FAQs')));
const Envelopes = Loadable(lazy(() => import('./pages/Envelopes')));
const LanyardPouch = Loadable(lazy(() => import('./pages/LanyardPouch')));
const MagnetBacker = Loadable(lazy(() => import('./pages/MagnetBacker')));
const PlaceCards = Loadable(lazy(() => import('./pages/PlaceCards')));
const RSVP = Loadable(lazy(() => import('./pages/RSVP')));
const Resellers = Loadable(lazy(() => import('./pages/Resellers')));
const BuildStadium = Loadable(lazy(() => import('./pages/BuildStadium')));
const BuildPhoto = Loadable(lazy(() => import('./pages/BuildPhoto')));
const ProofPhoto = Loadable(lazy(() => import('./pages/ProofPhoto')));

// coupon pages
const CouponsList = Loadable(
  lazy(() => import('./pages/dashboard/CouponsList'))
);
const CouponNew = Loadable(lazy(() => import('./pages/dashboard/CouponNew')));
const CouponEdit = Loadable(lazy(() => import('./pages/dashboard/CouponEdit')));

// reseller pagesw
const ResellersList = Loadable(
  lazy(() => import('./pages/dashboard/ResellersList'))
);
const ResellerNew = Loadable(
  lazy(() => import('./pages/dashboard/ResellerNew'))
);
const ResellerEdit = Loadable(
  lazy(() => import('./pages/dashboard/ResellerEdit'))
);
const ResellerDetails = Loadable(
  lazy(() => import('./pages/dashboard/ResellerDetails'))
);

// category pages
const CategoriesList = Loadable(
  lazy(() => import('./pages/dashboard/CategoriesList'))
);
const CategoryNew = Loadable(
  lazy(() => import('./pages/dashboard/CategoryNew'))
);
const CategoryEdit = Loadable(
  lazy(() => import('./pages/dashboard/CategoryEdit'))
);

// examples pages
const ExamplesList = Loadable(
  lazy(() => import('./pages/dashboard/ExamplesList'))
);
// const PhotoExamplesList = Loadable(lazy(() => import('./pages/dashboard/examples/photo/ExamplesList')));
const ExampleEdit = Loadable(
  lazy(() => import('./pages/dashboard/ExampleEdit'))
);

// account order pages
const AccountOrderDetails = Loadable(
  lazy(() => import('./pages/account/OrderDetails'))
);

// order pages
const OrderDetails = Loadable(
  lazy(() => import('./pages/dashboard/OrderDetails'))
);
const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
// const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired'))
);
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Projects pages

const Contact = Loadable(lazy(() => import('./pages/Contact')));
const Home = Loadable(lazy(() => import('./pages/Home')));
const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
const Cart = Loadable(lazy(() => import('./pages/Cart')));

const routes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <DashboardAccount />,
      },
      {
        path: '/analytics',
        element: <DashboardResellerAnalytics />,
      },
      {
        path: 'account',
        element: <DashboardAccount />,
      },
      {
        path: 'users',
        children: [
          {
            path: '/',
            element: <UsersList />,
          },
          {
            path: ':userId/edit',
            element: <UserEdit />,
          },
        ],
      },
      {
        path: 'coupons',
        children: [
          {
            path: '/',
            element: <CouponsList />,
          },
          {
            path: 'new',
            element: <CouponNew />,
          },
          {
            path: ':couponId/edit',
            element: <CouponEdit />,
          },
        ],
      },
      {
        path: 'resellers',
        children: [
          {
            path: '/',
            element: <ResellersList />,
          },
          {
            path: 'new',
            element: <ResellerNew />,
          },
          {
            path: ':resellerId',
            element: <ResellerDetails />,
          },
          {
            path: ':resellerId/edit',
            element: <ResellerEdit />,
          },
        ],
      },
      {
        path: 'categories',
        children: [
          {
            path: '/',
            element: <CategoriesList />,
          },
          {
            path: 'new',
            element: <CategoryNew />,
          },
          {
            path: ':categoryId',
            element: <ResellerDetails />,
          },
          {
            path: ':edit/edit',
            element: <CategoryEdit />,
          },
        ],
      },
      {
        path: 'tickets',
        children: [
          {
            path: '/images',
            element: <TicketImage />,
          },
          {
            path: '/images/new',
            element: <TicketImageCreate />,
          },
        ],
      },
      {
        path: 'examples',
        children: [
          {
            path: '/',
            element: <ExamplesList type="stadium" />,
          },
          {
            path: 'photo',
            children: [
              {
                path: '/',
                element: <ExamplesList type="photo" />,
              },
              {
                path: ':edit/edit',
                element: <ExampleEdit type="photo" />,
                // element: <ExampleEdit type="stadium" />,
              },
            ],
          },
          {
            path: 'stadium',
            children: [
              {
                path: '/',
                element: <ExamplesList type="stadium" />,
              },
              {
                path: ':edit/edit',
                element: <ExampleEdit />,
                // element: <ExampleEdit type="stadium" />,
              },
            ],
          },
        ],
      },
      {
        path: 'customers',
        children: [
          {
            path: '/',
            element: <CustomerList />,
          },
          {
            path: 'new',
            element: <CustomerEdit />,
          },
          {
            path: ':customerId',
            element: <CustomerDetails />,
          },
          {
            path: ':customerId/edit',
            element: <CustomerEdit />,
          },
        ],
      },
      {
        path: 'orders',
        children: [
          {
            path: '/',
            element: <OrderList />,
          },
          {
            path: ':orderId',
            element: <OrderDetails />,
          },
        ],
      },
    ],
  },
  {
    path: 'authentication',
    element: <MainLayout />,
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <GuestLogin />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <GuestLogin />,
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: 'register-unguarded',
        element: <Register />,
      },
      {
        path: 'verify-code',
        element: <VerifyCode />,
      },
    ],
  },
  {
    path: 'account',
    element: (
      <AuthGuardFront>
        <MainLayout />
      </AuthGuardFront>
    ),
    children: [
      {
        path: '/',
        element: <Account />,
      },
      {
        path: 'orders/:orderId',
        element: <AccountOrderDetails />,
      },
    ],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'build',
        // element: ({ children }) => <>{children}</>,
        children: [
          {
            path: '*',
            element: <Build />,
          },
          {
            path: 'stadium',
            element: <BuildStadium />,
          },
          {
            path: 'stadium/review',
            element: <BuildStadium />,
          },
          {
            path: 'stadium/e/:edit',
            element: <BuildStadium />,
          },
          {
            path: 'stadium/proof/:edit',
            element: <BuildStadium />,
          },
          {
            path: 'photo',
            element: <BuildPhoto />,
          },
          {
            path: 'photo/review',
            element: <BuildPhoto />,
          },
          {
            path: 'photo/e/:edit',
            element: <BuildPhoto />,
          },
          {
            path: 'photo/proof/:edit',
            element: <ProofPhoto />,
          },
        ],
      },
      {
        path: 'add-ons',
        element: <Addons />,
      },
      {
        path: 'shipping',
        element: <Shipping />,
      },
      {
        path: 'faqs',
        element: <FAQs />,
      },
      {
        path: 'envelopes',
        element: <Envelopes />,
      },
      {
        path: 'lanyard-pouch',
        element: <LanyardPouch />,
      },
      {
        path: 'magnet-backer',
        element: <MagnetBacker />,
      },
      {
        path: 'place-cards',
        element: <PlaceCards />,
      },
      {
        path: 'rsvp',
        element: <RSVP />,
      },
      {
        path: 'resellers',
        element: <Resellers />,
      },
      {
        path: 'contact-Us',
        element: <Contact />,
      },
      {
        path: 'cart',
        element: <Cart />,
      },
      {
        path: 'checkout',
        element: <Checkout />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
