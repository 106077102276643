import numeral from 'numeral';

/* eslint-disable no-nested-ternary */
const perTicketPrice = (qty = 0, type = 'photo') => {
  if (type === 'stadium') {
    if (qty > 250) return numeral(1.09).value();
    if (qty > 100) return numeral(1.29).value();
    if (qty > 25) return numeral(1.39).value();
    if (qty > 10) return numeral(1.59).value();
    if (qty > 0) return numeral(1.69).value();
  }

  return (
    qty <= 50
      ? numeral(1.99)
      : qty <= 200
      ? numeral(1.89)
      : qty <= 499
      ? numeral(1.79)
      : numeral(1.69)
  ).value();
};

export default perTicketPrice;
